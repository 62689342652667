.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.imageContainer1,
.imageContainer2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("/src/data/img/background-header.webp");
    background-position: center;
    background-size: cover;
}

.image {
    height: 70vh;
}

.titlesContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    z-index: 10;
    margin-top: 20%;
    height: 100vh;
}

.titleContainerLeft,
.titleContainerRight {
    width: 40%;
    text-align: left;
}

.titleContainerRight {
    text-align: right;
}

.title {
    font-size: 5rem;
    margin-bottom: 1rem;
    transition: opacity 0.5s ease;
}

.hidden {
    opacity: 0;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 960px) {
    .container{
        height: 50vh;
    }

    .titlesContainer{
        width: 85%;
    }
    .titleContainerLeft, .titleContainerRight {
        width: 80%;
    }
    .title{
        font-size: 3rem;
        margin-bottom: 1rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .container{
        height: 50vh;
    }

    .titlesContainer{
       display: none;
    }
    .image{
        height: 35vh;
    }
}