.area{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.overview{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}



.number{
    text-align: center;
    font-weight: bold;
}

.number:hover{
    cursor: default;
}



.icon{
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    text-align: center;
    margin-bottom: 5%;
}

.mobileOverview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

@media (max-width: 767px) {
    .area{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-content: center;
        margin: 2rem 0 0 0;
    }
    .overview{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0;
        max-width: 25%;
    }

    .icon{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

}