.section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 20rem;
    margin: 2rem 0;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageBg{
    background-image: url("/src/data/img/background-cards.webp");
    background-position: left;
    background-size: cover;
}


.image{
    width: 25%;
    border-radius: 50%;
    border: #777777 5px solid;
    float: left;
    margin: 0 5% 2% 0;
    background: white;
}

.personal{

}

.title{
    text-transform: uppercase;
    text-align: center;
}

.text{
    text-align: justify;
    margin-bottom: 3rem;
}

.button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.rightSection{
    width: 85%;
}

.leftSection{
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}



@media (max-width: 1366px) {
    .container{
        padding: 7% 0;
    }
    .image{
        width: 20rem;
        height: 20rem;
    }
}

@media (max-width: 767px) {
    .section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 10rem;
    }

    .container{
        padding: 5% 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .image{
        width: 100%;
        height: auto;
        border-radius: 0;
        border: none;
        margin-bottom: 0;
    }

    .title{
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .personal{
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .text{
        text-align: justify;
        margin-bottom: 2rem;
    }

}