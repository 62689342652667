.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* GRID SECTION */

.gridSection {
    display: grid;
    gap: 1rem;
    margin: 0 auto;
}

/* Pattern per 4 elementi che si ripete */
.gridPatternPrimary {
    display: grid;
    gap: 2rem;
    grid-template-areas:
        "box1 box1 box2 box3"
        "box1 box1 box4 box3"
        "box6 box6 box4 box5"
        "box8 box9 box9 box7"
        "box8 box10 box11 box11";
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 1rem;
}

.gridPatternSecondary {
    display: grid;
    gap: 2rem;
    grid-template-areas:
        "box12 box13 box14 box15";
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 1rem;
}

.box1, .box2, .box3, .box4, .box5, .box6,
.box7, .box8, .box9, .box10, .box11, .box12, .box13, .box14, .box15 {
    text-align: left;
    padding: 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box1 {
    grid-area: box1;
}

.box2 {
    grid-area: box2;
}

.box3 {
    grid-area: box3;
}

.box4 {
    grid-area: box4;
}

.box5 {
    grid-area: box5;
}

.box6 {
    grid-area: box6;
}

.box7 {
    grid-area: box7;
}

.box8 {
    grid-area: box8;
}

.box9 {
    grid-area: box9;
}

.box10 {
    grid-area: box10;
}

.box11 {
    grid-area: box11;
}

.box12 {
    grid-area: box12;
}

.box13 {
    grid-area: box13;
}

.box14 {
    grid-area: box14;
}

.box15 {
    grid-area: box15;
}

.boxA {
    grid-area: boxA;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.boxB {
    grid-area: boxB;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
    background-color: white;
}

.boxC {
    grid-area: boxC;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gridBox1 {
    display: grid;
    gap: 1rem;
    grid-template-areas:
    "boxA boxA boxB"
    "boxA boxA boxB"
    "boxC boxC boxB";
    grid-template-columns: repeat(3, 1fr);
}

.gridBox2, .gridBox5, .gridBox7, .gridBox10, .gridBox12, .gridBox13, .gridBox14, .gridBox15 {
    display: grid;
    grid-template-areas:
    "boxA"
    "boxC";
    grid-template-columns: repeat(0, 1fr);
}

.gridBox2 .boxB, .gridBox5 .boxB, .gridBox7 .boxB, .gridBox10 .boxB, .gridBox12 .boxB, .gridBox13 .boxB, .gridBox14 .boxB, .gridBox15 .boxB {
    display: none;
}

.gridBox2 .titleProject, .gridBox5 .titleProject, .gridBox7 .titleProject, .gridBox10 .titleProject, .gridBox12 .titleProject, .gridBox13 .titleProject, .gridBox14 .titleProject, .gridBox15 .titleProject {
    min-height: 2.5rem;
    overflow: visible;
}

.gridBox3, .gridBox8 {
    display: grid;
    grid-template-areas:
    "boxB"
    "boxA"
    "boxC";
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem
}

.gridBox3 .boxB, .gridBox8 .boxB {
    object-fit: cover;
}

.gridBox4 {
    display: grid;
    grid-template-areas:
    "boxB"
    "boxA"
    "boxC";
    grid-template-columns: repeat(0, 1fr);
    gap: 1rem;
}

.gridBox4 .boxB {
    object-fit: cover;
}

.gridBox6, .gridBox9, .gridBox11 {
    display: grid;
    grid-template-areas:
    "boxA boxB"
    "boxC boxB";
    grid-template-columns: repeat(0, 1fr);
    gap: 1rem
}

.gridBox6 .boxB, .gridBox9 .boxB, .gridBox11 .boxB {
    object-fit: cover;
}

.languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--black)
}

.language {
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 7px;
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--black);
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 7px;
    margin-right: 1rem;
}

.icon:hover {
    background: var(--lightBlue);
    cursor: pointer;
    color: var(--blue);
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/* END GRID SECTION*/


.section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    transition: all 350ms ease;
}

.title {
    text-transform: uppercase;
    margin: 3rem 0;
}

.titleProject {
    font-size: 32px;
}

.datore {
    margin: 0.5rem 0;
}

.filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
}

.filter {
    color: #0b4e6c;
    transition: all 0.5s ease 0s;
    margin-left: 2rem;
    cursor: pointer;
}

.filterActive {
    margin-bottom: 0.4rem;
    border-bottom: #f59e24 solid 2px;
    color: #f59e24;
    transition: all 0.5s ease 0s;
    margin-left: 2rem;
    cursor: pointer;
}

.filter:hover {
    margin-bottom: 0.4rem;
    border-bottom: #f59e24 solid 2px;
    color: #f59e24;
    transition: all 350ms ease;
}

.projectCard {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.projectCard.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 1024px) {
    .section {
        justify-content: space-evenly;
    }

    .filters {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 3rem;
    }
}

@media (max-width: 767px) {

    .boxB{
        display: none;
    }

    .container{
        padding: 0 !important;
    }

    .title {
        margin: 5%;
    }

    .gridSection {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .gridPatternPrimary {
        display: flex;
        flex-direction: column;
    }

    .gridPatternSecondary {
        display: flex;
        flex-direction: column;
    }

    .gridBox1, .gridBox6 {
        display: grid;
        grid-template-areas:
        "boxB"
        "boxA"
        "boxC";
        grid-template-columns: repeat(1, 1fr);
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 0;
    }

    .filters {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 5%;
        transition: all 1s ease-in-out;
    }

    .filter {
        color: #0b4e6c;
        transition: all 0.5s ease 0s;
        margin-left: 0;
        cursor: pointer;
    }

    .filterActive {
        margin-bottom: 0.4rem;
        border-bottom: #f59e24 solid 2px;
        color: #f59e24;
        transition: all 0.5s ease 0s;
        margin-left: 0;
        cursor: pointer;
    }

}