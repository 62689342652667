.paper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white !important;
    box-shadow: 0 10px 27px 3px rgba(204, 204, 204, 0.2);
    position: relative;
    padding: 2rem;
    font-weight: 300;
    width: 20rem;
    height: 7rem;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: row;
    text-align: center !important;
}

.a{
    color: var(--orange),
}

.a:hover{
    color: var(--blue),
}

@media (max-width: 1366px){
    .paper{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: white !important;
        box-shadow: 0 10px 27px 3px rgba(204, 204, 204, 0.2);
        width: 25vw;
        padding: 2rem 1rem;
        font-weight: 300;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .paper{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: white !important;
        box-shadow: 0 10px 27px 3px rgba(204, 204, 204, 0.2);
        width: 60vw;
        padding: 2rem 1rem;
        font-weight: 300;
        text-align: center;
    }
}