.image{
    width: 65%;
}

.icon:hover{
    color: var(--blue);
}

.radialMenu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .image{
        width: 25%;
    }
}