.container{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    background-image: url("/src/data/img/background-header.webp");
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .container{
        height: 30vh;
    }
}