.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logoContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.section {
    margin: 2rem 0;
}

.title {
    text-transform: uppercase;
    text-align: center;
    margin-top: 4rem;
}

.carouselWrapper {
    display: flex;
    transition: opacity 0.3s;
}

.carouselWrapper.visible {
    opacity: 1;
}

.carouselWrapper.hidden {
    opacity: 0;
}

.carouselTrack {
    display: flex;
    animation: scroll 60s linear infinite;
}

.image {
    height: 4rem;
    object-fit: contain;
    margin: 0 3rem;
}

.button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .logoContainer {
        position: relative;
        width: 85%;
        overflow: hidden;
        margin-left: 15%
    }

}