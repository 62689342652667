.container{
    display: flex;
    flex-direction: column;
}

.skills{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}


.hr{
    width: 20%;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background-color: var(--orange);
    color: var(--orange);
    border: 1px solid var(--orange);
}

.label{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-weight: 300;
}

.icon{
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 0 0.5rem;
}

.progressItem{
    width: 48%;
}

.progressBar{
    width: 100%;
}

.title{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2rem;
}

@media (max-width: 767px) {
    .container{
        display: flex;
        flex-direction: column;
        margin-top: 2rem
    }

    .skills{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

    }
    .progressItem{
        width: 80%;
        margin: 0.5rem 0;
    }

    .label{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 300;
    }
}