@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root{
  --blue: #0b4e6c;
  --orange: #f59e24;
  --black: #222222;
  --gray: #777777;
  --white: #fff;
  --lightGrey: #f8f9fa;
  --lightBlue: #dbe0ff;
  --lightOrange: rgb(255, 223, 180);
  --lightGreen: rgb(209, 255, 220);
}

body {
  background: var(--lightGrey);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7em;
  position: relative;
  padding: 0;
  margin:0;
}

section{
  padding: 5% 12% 5% 12%;
}

h1, h2, h3,
h4, h5, h6 {
  font-family: "Maven Pro", sans-serif;
  line-height: 1.2em;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 800;
}

.h1, .h2, .h3,
.h4, .h5, .h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Maven Pro", sans-serif;
  font-weight: 800;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
  color: var(--black);
}

blockquote{
  padding: 1rem 2rem 1rem 2.5rem;
  background: var(--white);
  border-left: 2px solid var(--black);
  color: var(--orange);
  margin: 5% 0;
}

blockquote a{
  color: var(--orange) !important;
}

a {
  text-decoration: none;
}

.explainProject{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 5% 0;
}


.explainProject p{
  width: 100%;
  padding: 0 0 0 5%;
  text-align: justify;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  /* Posizionamento sopra l'elemento */
  position: absolute;
  font-size: 0.7rem;
  bottom: 100%;    /* Posiziona il tooltip sopra l'elemento */
  left: 50%;       /* Centra orizzontalmente */
  transform: translateX(-50%);  /* Aggiusta il centraggio */
  margin-bottom: 5px;  /* Spazio tra il tooltip e l'elemento */

  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 1;
}

progress {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 0.7rem;
  border: none;
  border-radius: 10px;
  background-color: var(--lightBlue);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Ombra interna sottile */
  padding: 0.1rem;
}

progress::-webkit-progress-bar {
  background-color: var(--lightBlue);
  border-radius:0.5rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

progress::-webkit-progress-value {
  background: var(--blue);
  border-radius:0.5rem;
  transition: width 0.3s ease;
}

progress::-moz-progress-bar {
  background: var(--blue);
  border-radius:0.5rem;
}
@media (max-width: 1025px) {

}

@media (max-width: 767px){

  blockquote{
    padding: 1rem 1rem 1rem 2.5rem;
    background: #fff;
    border-left: 2px solid var(--lightBlue);
    color: var(--orange);
    margin: 10% 0;
  }

  .explainProject{
    flex-flow: column nowrap;
    margin: 10% 0;
  }

  .explainProject p{
    padding: 0;
  }
}