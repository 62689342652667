.container {
    text-align: center;
    margin-bottom: 2rem;

}

.hr{
    background-color: var(--orange);
    color: var(--orange);
    border: 1px solid var(--orange);
    border-radius: 10px;
    width: 40%;
    margin-bottom: 1rem;
}

@media (max-width: 767px) {
    .container {
        text-align: center;
        margin-bottom: 5rem;

    }

}