.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    margin: 2rem 0;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
}

.cover{
    height: 10rem;
    object-fit: cover;
    width: 100%;
    border-radius: 0.5rem;
}

.content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 60%;
    text-align: left;
}

.title{
    color: var(--black);
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
}

.description{
    margin: 0.5rem 0;
    height: 6rem;
    color: var(--gray);
    font-size: 12px;
}

.language{
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 7px;
}

.hr{
    visibility: hidden;
}

.datore{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.languages{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--black);
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 7px;
    margin-right: 1rem;
}

.icon:hover {
    background: var(--lightBlue);
    cursor: pointer;
    color: var(--blue);
}

.links{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}