.title{
    text-align: center;
    text-transform: uppercase;
    margin-top: 2rem;
}

@media (max-width: 767px) {
    .title{
        margin-top: 2rem;
    }
}