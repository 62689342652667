.container {
    background: var(--white);
    border-radius: 0.5rem;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    padding: 5% 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 767px) {
    .container {
        padding: 5% 0;
    }
}



